import React from 'react';
import CapabilityBadges from './CapabilityBadges';
// import ModelScoreBarChart from './ModelScoreBarChart';

const ModelButton = ({ model, selectedModel, setSelectedModel, isPopup = false, isDetail, toggleDetail, isMain = false}) => (
    <div 
        className={`flex ${isPopup ? 'flex-col' : 'items-center justify-center'} bg-none hover:bg-hover dark:hover:bg-hover-dark border border-gray-200 dark:border-gray-700 ${isPopup ? 'p-5 my-3' : 'p-3'} ${isPopup ? 'rounded-lg' : 'rounded-2xl'} cursor-pointer w-full ${isPopup ? 'h-auto' : 'h-9'} ${
            selectedModel === model.id ? 'border-primary dark:border-primary text-primary dark:text-primary' : ''} hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300`}
        onClick={() => setSelectedModel(model.id)}
    >
        <div className="flex items-center justify-between">
            <div className="flex items-center" onClick={() => setSelectedModel(model.id)}>
                <img className="h-4 mr-2 rounded-sm" src={model.logo} alt={`${model.name} Logo`} />
                <p className={`text-sm flex ${isPopup ? 'font-semibold' : 'font-normal'}`}>{model.name}</p>
            </div>
            {isPopup && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDetail();
                    }}
                    className="text-sm text-primary hover:text-primary-dark p-1 rounded-full transition-all duration-300 ease-in-out hover:bg-white border-border border"
                >
                    <svg
                        className="w-4 h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#7cb342"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        {isDetail ? (
                            <path d="M18 15l-6-6-6 6" />
                        ) : (
                            <path d="M6 9l6 6 6-6" />
                        )}
                    </svg>
                </button>
            )}
        </div>
        {isPopup && (
            <>
                <hr className="my-2 border-border dark:border-border-dark" />
                <p className="text-xs mt-1 text-gray-500 dark:text-gray-500 flex items-center">
                    <svg className="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                        <path d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z"/>
                    </svg>
                    Strength: {model.strength}
                    <span className="mx-2">•</span>
                    <svg className="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>
                    Speed: {model.speed}/10
                    <span className="mx-2">•</span>
                    <svg className="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="currentColor">
                        <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
                        <text x="12" y="17" fontFamily="Arial" fontSize="14" textAnchor="middle" fill="currentColor">€</text>
                    </svg>
                    Cost: {model.cost} credits
                </p>
                {isDetail && (
                    <>
                        <hr className="my-2 border-border dark:border-border-dark" />
                        <CapabilityBadges model={model} />
                        {/* <hr className="my-2 border-border dark:border-border-dark" />
                        {model.id === 'web-search' && (
                            <>
                                <p className="text-xs mt-1 text-text dark:text-text-dark">Searchy is powered by GPT-4o mini and not benchmarked separately</p>
                            </>
                        )} */}
                        {/* {model.id !== 'web-search' && (
                            <>  
                                <ModelScoreBarChart scores={model.scores} />
                            </>
                        )} */}

                    </>
                )}
            </>
        )}
    </div>
);

export default ModelButton;