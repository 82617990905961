import React, { useEffect, useRef } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-markup';
import '@tailwindcss/typography';

import BufferingAnimation from './BufferingAnimation';

import OpenAILogoDark from '../assets/logo-openai-dark.svg';
import OpenAILogoLight from '../assets/logo-openai-light.svg';
import ClaudeLogoDark from '../assets/logo-claude-dark.svg';
import ClaudeLogoLight from '../assets/logo-claude-light.svg';
import GeminiLogo from '../assets/logo-gemini-dark.png';
import EllyLogo from '../assets/logo-elly.png';
import MistralLogo from '../assets/logo-mistral.png';

marked.setOptions({
  highlight: function(code, lang) {
      // Handle HTML specifically
      if (lang === 'html') {
          return Prism.highlight(
              code,
              Prism.languages.markup,
              'markup'
          );
      }
      // Handle other languages as before
      if (Prism.languages[lang]) {
          return Prism.highlight(code, Prism.languages[lang], lang);
      }
      return code;
  }   
});

const renderer = new marked.Renderer();

// Custom code block renderer
renderer.code = (code, language) => {
  const lang = code.lang || language || 'plaintext';
  
  // Escape HTML characters for display while preserving formatting
  const codeText = typeof code === 'string' ? code : code.text || '';
  const escapedCode = codeText.replace(/&/g, '&amp;')
                               .replace(/</g, '&lt;')
                               .replace(/>/g, '&gt;');
  
  return `
      <div class="code-block relative rounded-lg overflow-hidden border border-border dark:border-border-dark bg-whitesmoke dark:bg-secondary-dark shadow-md my-4 max-w-52 sm:max-w-full">
          <div class="flex justify-between items-center bg-secondary dark:bg-secondary-dark px-4 py-1 font-mono">
              <span class="text-text dark:text-text-dark text-sm">${lang}</span>
              <button class="copy-button bg-whitesmoke dark:bg-secondary-dark hover:bg-primary hover:text-white text-gray-600 px-2 py-1 rounded transition-colors duration-200 ease-in-out cursor-pointer flex items-center justify-center w-20">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                  </svg>
                  <p class="text-xs">Copy</p>
              </button>
          </div>
          <div class="overflow-x-auto">
              <pre class="rounded-lg overflow-hidden"><code class="my-0 language-${lang}">${escapedCode}</code></pre>
          </div>
      </div>
  `;
};

// Markdown parser
const parseMarkdown = (text) => {
  const rawMarkup = marked(text, { renderer: renderer });
  return { __html: DOMPurify.sanitize(rawMarkup) };
};

// Chat message component
const ChatMessage = ({ user, selectedModel, message, streamedResponse, darkMode, isBuffering }) => {
  const messageRef = useRef(null);
    
  useEffect(() => {
    Prism.highlightAll();
  
    if (messageRef.current) {
      const copyButtons = messageRef.current.querySelectorAll('.copy-button');
      copyButtons.forEach(button => {
        button.addEventListener('click', () => {
          const codeBlock = button.closest('.code-block').querySelector('code');
          const originalHTML = button.innerHTML;
          navigator.clipboard.writeText(codeBlock.textContent).then(() => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" /></svg><p class="text-xs">Copied!</p>';
            setTimeout(() => {
              button.innerHTML = originalHTML;
            }, 1000);
          });
        });
      });
    }
  }, [message, streamedResponse]);

  // Assistant logos
  const assistantLogos = {
    'claude-3-5-sonnet-20240620': { light: ClaudeLogoDark, dark: ClaudeLogoLight },
    'claude-3-5-sonnet-20241022': { light: ClaudeLogoDark, dark: ClaudeLogoLight },
    'claude-3-opus-20240229': { light: ClaudeLogoDark, dark: ClaudeLogoLight },
    'claude-3-sonnet-20240229': { light: ClaudeLogoDark, dark: ClaudeLogoLight },
    'claude-3-haiku-20240307': { light: ClaudeLogoDark, dark: ClaudeLogoLight },
    'claude-3-5-haiku-20241022': { light: ClaudeLogoDark, dark: ClaudeLogoLight },
    'gpt-4o': { light: OpenAILogoDark, dark: OpenAILogoLight },
    'gpt-4o-mini': { light: OpenAILogoDark, dark: OpenAILogoLight },
    'gpt-4-turbo': { light: OpenAILogoDark, dark: OpenAILogoLight },
    'gemini-1.0-pro': { light: GeminiLogo, dark: GeminiLogo }, 
    'gemini-1.5-pro': { light: GeminiLogo, dark: GeminiLogo },
    'gemini-1.5-flash': { light: GeminiLogo, dark: GeminiLogo },
    'web-search': { light: EllyLogo, dark: EllyLogo },
    'mistral-small-latest': { light: MistralLogo, dark: MistralLogo },
    'mistral-large-latest': { light: MistralLogo, dark: MistralLogo },
    'ministral-3b-latest': { light: MistralLogo, dark: MistralLogo },
    'pixtral-12b-2409': { light: MistralLogo, dark: MistralLogo }
  };

  // Get the assistant logo for the given model
  const getAssistantLogo = (model, selectedModel) => {
    return assistantLogos[model] || assistantLogos[selectedModel] || { light: OpenAILogoDark, dark: OpenAILogoLight };
  };

  // Get the image URLs from the message content
  const getImageUrls = (content) => {
    if (Array.isArray(content)) {
      return content
        .filter(item => item.type === 'image' || item.type === 'image_url')
        .map(imageItem => {
          if (imageItem.type === 'image') {
            return `data:${imageItem.source.media_type};base64,${imageItem.source.data}`;
          } else {
            return imageItem.image_url.url;
          }
        });
    }
    return [];
  };

  const getFileBadgeStyle = (fileType) => {
    switch (fileType) {
      case 'PDF':
        return 'bg-red-500 text-white';
      case 'DOCX':
        return 'bg-blue-500 text-white';
      case 'CSV':
        return 'bg-green-500 text-white';
      case 'TXT':
        return 'bg-gray-500 text-white';
      case 'XLSX':
        return 'bg-emerald-500 text-white';
      case 'JSON':
        return 'bg-yellow-500 text-black';
      default:
        return 'bg-gray-300 text-black';
    }
  };

  const renderFileAttachments = (content) => {
    const fileTypes = ['PDF', 'DOCX', 'CSV', 'TXT', 'XLSX', 'JSON'];
    const attachments = fileTypes.map(fileType => {
      const fileContent = content.find(item => item.text && item.text.startsWith(`${fileType}`));
      if (fileContent) {
        // const fileName = fileContent.text.split(':')[1].trim().split('\n')[0].split(' ').slice(1).join(' ').replace(/\.[^.]+$/, '');
        const fileName = fileContent.text
          .split(':')[1]
          .trim()
          .split('.')[0]
          .trim();

        return (
          <div key={fileType} className="flex items-center rounded-sm py-1">
            <span className={`${getFileBadgeStyle(fileType)} text-xs font-bold mr-2 px-2.5 py-0.5 rounded`}>
              {fileType}
            </span>
            <span className="text-sm text-text dark:text-text-dark">{fileName}</span>
          </div>
        );
      }
      return null;
    }).filter(Boolean);

    if (attachments.length > 0) {
      return (
        <div className="flex flex-col space-y-2 w-full pb-2">
          {attachments}
        </div>
      );
    }
    return null;
  };

  const { text, imageUrls } = Array.isArray(message.content)
  ? {
      text: message.content.find(item => item.type === 'text')?.text || '',
      imageUrls: getImageUrls(message.content)
    }
  : { text: message.content, imageUrls: [] };
  
  // Render the chat message
  return (
    <div ref={messageRef} className={`flex w-full ${
      message.role === 'user' ? 'justify-end' : 'justify-start'
    }`}>
      <div className={`flex items-start p-3 m-2 rounded-2xl ${
        message.role === 'assistant' 
          ? 'bg-transparent w-full'
          : 'bg-secondary bg-opacity-50 dark:bg-secondary-dark dark:bg-opacity-80 shadow-sm max-w-[70%]'
      }`}>
        {message.role === 'assistant' && (
          <div className="w-5 h-5 mt-1 ml-2 mr-3 flex-shrink-0">
            <img
              className="w-full h-full object-cover hidden dark:block"
              src={getAssistantLogo(message.model, selectedModel).dark}
              alt="Assistant Logo (Dark)"
            />
            <img
              className="w-full h-full object-cover block dark:hidden"
              src={getAssistantLogo(message.model, selectedModel).light}
              alt="Assistant Logo (Light)"
            />
          </div>
        )}

        <div className="!max-w-full overflow-x-auto prose prose-sm sm:prose prose-headings:my-0 prose-p:my-0 prose-ul:my-0 prose-ol:my-0 prose-pre:my-0 prose-blockquote:my-0 prose-code:text-text dark:prose-code:text-text-dark dark:prose-strong:text-text-dark dark:prose-headings:text-text-dark dark:prose-a:text-text-dark">
          <div className="flex flex-wrap gap-2">
            {imageUrls.map((url, index) => (
              <img key={index} src={url} alt={`Uploaded content ${index + 1}`} className="max-w-32 max-h-32 rounded-lg" />
            ))}
            {Array.isArray(message.content) && renderFileAttachments(message.content)}
          </div>
          {isBuffering ? (
            <BufferingAnimation darkMode={darkMode} />
          ) : (
            <div className="my-0 dark:text-text-dark" dangerouslySetInnerHTML={parseMarkdown(text)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;