import React, { useState, useEffect } from 'react';

const WelcomeMessage = ({ user }) => {
    const [displayedText, setDisplayedText] = useState('');
    
    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return 'Good morning';
        if (hour < 18) return 'Good afternoon';
        return 'Good evening';
    };

    const firstName = user.name.split(' ')[0];
    const firstPart = `${getGreeting()}, ${firstName}.`;
    const secondPart = 'How can I help?';
    const fullMessage = `${firstPart}\n${secondPart}`;

    useEffect(() => {
        const startDelay = setTimeout(() => {
            let currentIndex = 0;
            const typingInterval = setInterval(() => {
                if (currentIndex <= firstPart.length) {
                    setDisplayedText(fullMessage.slice(0, currentIndex));
                    currentIndex++;
                } else if (currentIndex === firstPart.length + 1) {
                    setTimeout(() => {
                        currentIndex++;
                    }, 500);
                } else if (currentIndex <= fullMessage.length) {
                    setDisplayedText(fullMessage.slice(0, currentIndex));
                    currentIndex++;
                } else {
                    clearInterval(typingInterval);
                }
            }, 60);

            return () => clearInterval(typingInterval);
        }, 1600);

        return () => clearTimeout(startDelay);
    }, [fullMessage, firstPart]);

    return (
        <div className="text-xl text-center text-text dark:text-text-dark h-20">
            <br />
            {displayedText.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                    <span className="relative">
                        {i === 0 ? line : <span className="font-semibold">{line}</span>}
                    {i === displayedText.split('\n').length - 1 && (
                        <span className="inline-block w-3 h-3 bg-primary rounded-full ml-1 mb-0.5 animate-pulse" />
                        )}
                    </span>
                    {i === 0 && <br />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default WelcomeMessage;