import React from 'react';
import Profile from './Profile';

// Render header component, including profile component
const Header = ({ className, darkMode, toggleDarkMode, creditsBalance, setCreditsBalance, addSystemPrompt, setAddSystemPrompt, systemPrompt, setSystemPrompt }) => {
    return (
      <div className={`w-full bg-background  dark:bg-background-dark px-4 ${className}`}>
        <div className="flex justify-center items-center h-full mx-auto">
          <div className="flex-grow">
          </div>
          <Profile 
            darkMode={darkMode} 
            toggleDarkMode={toggleDarkMode} 
            creditsBalance={creditsBalance}
            setCreditsBalance={setCreditsBalance}
            addSystemPrompt={addSystemPrompt}
            setAddSystemPrompt={setAddSystemPrompt}
            systemPrompt={systemPrompt}
            setSystemPrompt={setSystemPrompt}
          />
        </div>
      </div>
    );
};

export default Header;