import React from 'react';

const Tooltip = ({ children, content, detailed = false }) => {
  const tooltipClasses = `absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 text-text dark:text-text-dark border border-border dark:border-border-dark text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300 pointer-events-none bg-secondary dark:bg-secondary-dark hidden sm:block ${
    detailed ? 'w-64' : 'w-72'
  }`;

  const renderDetailedContent = (model) => (
    <div className="text-sm">
      <div className="flex items-center mb-1">
        <img src={model.logo} alt={`${model.name} logo`} className="h-4 mr-2" />
        <span className="font-bold">{model.name}</span>
      </div>
      <p className="mb-2 text-xs">{model.description}</p>
    </div>
  );

  return (
    <div className="relative inline-block group">
      {children}
      <div className={tooltipClasses}>
        {detailed ? renderDetailedContent(content) : content}
      </div>
    </div>
  );
};

export default Tooltip;