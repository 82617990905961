import React from 'react';

const CapabilityBadges = ({ model }) => {
    const capabilities = [
        { key: 'vision', label: 'Attach images & docs' },
        { key: 'multilingual', label: 'Understand multiple languages' },
        { key: 'search', label: 'Search the web' }
    ];

    return (
        <div className="flex flex-wrap gap-2 my-2">
            {capabilities.map(({ key, label }) => (
                <span 
                    key={key}
                    className={`px-2 py-1 rounded-full text-xs ${
                        model[key] 
                            ? 'bg-primary text-white' 
                            : 'bg-gray-200 text-gray-500 dark:bg-gray-700 dark:text-gray-400'
                    }`}
                >
                    {label}
                </span>
            ))}
        </div>
    );
};

export default CapabilityBadges;