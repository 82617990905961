// Helper function to validate domain
const isValidDomain = () => {
    const validDomains = [
        'localhost',
        'herokuapp.com',
        'ask-elly.co'
    ];
    
    return validDomains.some(domain => 
        window.location.hostname === domain || 
        window.location.hostname.endsWith('.' + domain)
    );
};

// Helper function to validate the API URL
const getSecureApiUrl = () => {
    // If we're in development, use the environment variable
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_URL;
    }

    // For production/review apps, validate domain and construct URL
    if (!isValidDomain()) {
        console.error('Invalid domain detected');
        return null;
    }

    return `${window.location.origin}/api`;
};

// Create a frozen config object that can't be modified
const config = Object.freeze({
    API_URL: getSecureApiUrl()
});

// Export the frozen API_URL directly
export const API_URL = config.API_URL;