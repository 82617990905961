import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthButton = ({ fullWidth }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const buttonClass = `h-10 bg-white border dark:bg-background-dark dark:text-text-dark border-border dark:border-border-dark p-1 rounded-md hover:bg-red-200 dark:hover:bg-red-400 transition-colors duration-300 text-sm ${
    fullWidth ? 'w-full' : 'w-28 m-2'
  }`;

  return (
    <div className={`flex justify-center ${fullWidth ? 'w-full' : ''}`}>
      {isAuthenticated ? (
        // If the user is authenticated, show the logout button
        <button 
          className={buttonClass}
          onClick={() => logout({ returnTo: "/" })}
        >
          Log Out
        </button>
      ) : (
        // If the user is not authenticated, show the login button
        <button 
          className="w-40 bg-primary text-white dark:text-white border-border dark:border-border-dark p-2 m-2 rounded-full hover:bg-primary-hover transition-colors duration-300 shadow-md text-lg" 
          onClick={() => loginWithRedirect()}
        >
          Get started
        </button>
      )}
    </div>
  );
};

export default AuthButton;