import React from 'react';

const BufferingAnimation = ({ darkMode }) => {
  return (
    <div className="flex items-end justify-start pt-3">
      <div className={`w-1 h-1 bg-gray-500 rounded-full mr-1 animate-bounce ${darkMode ? 'bg-gray-400' : 'bg-gray-600'}`} style={{ animationDelay: '0s' }}></div>
      <div className={`w-1 h-1 bg-gray-500 rounded-full mr-1 animate-bounce ${darkMode ? 'bg-gray-400' : 'bg-gray-600'}`} style={{ animationDelay: '0.2s' }}></div>
      <div className={`w-1 h-1 bg-gray-500 rounded-full animate-bounce ${darkMode ? 'bg-gray-400' : 'bg-gray-600'}`} style={{ animationDelay: '0.4s' }}></div>
    </div>
  );
};

export default BufferingAnimation;