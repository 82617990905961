import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AssistantsPopup from './AssistantsPopup';
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';

function Assistants({ user, selectedAssistant, setSelectedAssistant, setSelectedModel }) {
    const { isAuthenticated } = useAuth0();
    const [showAssistantsPopup, setShowAssistantsPopup] = useState(false);
    const [showDesignAssistantPopup, setShowDesignAssistantPopup] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [editingAssistant, setEditingAssistant] = useState(null);
    const [deletingAssistant, setDeletingAssistant] = useState(null);
    const [showDeleteAssistantPopup, setShowDeleteAssistantPopup] = useState(false);

    useEffect(() => {
        const fetchAssistants = async () => {
            if (!isAuthenticated || !user) {
                console.log('User not authenticated or user data not available');
                return;
            }
            
            try {
                const response = await fetch(`${API_URL}/assistants/${user.sub}`);
                const data = await response.json();
                setAssistants(data);
            } catch (error) {
                console.error('Error fetching assistants:', error);
                toast.error('Oops, something went wrong. Please try again.');
            }
        };

        fetchAssistants();
    }, [isAuthenticated, user]);

    const handleAssistantSubmit = async (assistantTitle, assistantInstruction, assistantModel, assistantId) => {
        try {
            const url = assistantId 
                ? `${API_URL}/assistants/edit/${assistantId}`
                : `${API_URL}/assistants/create`;
            
            const response = await fetch(url, {
                method: assistantId ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    userId: user.sub, 
                    title: assistantTitle, 
                    instruction: assistantInstruction,
                    model: assistantModel
                }),
            });

            if (!response.ok) throw new Error();
        
            // Refresh assistants list
            const updatedResponse = await fetch(`${API_URL}/assistants/${user.sub}`);
            const updatedAssistants = await updatedResponse.json();
            setAssistants(updatedAssistants);

            toast.success(assistantId ? 'Assistant updated' : 'Assistant created');
            
        } catch (error) {
            console.error('Error handling assistant:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }

        setShowDesignAssistantPopup(false);
        setEditingAssistant(null);
    };

    // Event handlers
    const handleAssistantClick = (assistant) => {
        if (assistant === null) {
            setSelectedAssistant(null);
            toast.success("Selected 'No assistant'");
        } else {
            setSelectedAssistant(assistant);
            if (assistant.model !== 'None') {
                setSelectedModel(assistant.model);
            }
            toast.success(`${assistant.decrypted_title} selected`);
        }
        setShowAssistantsPopup(false);
    };

    const handleEditClick = (e, assistant) => {
        e.stopPropagation();
        setEditingAssistant(assistant);
        setShowDesignAssistantPopup(true);
    };

    const handleDeleteClick = (e, assistant) => {
        e.stopPropagation();
        setDeletingAssistant(assistant);
        setShowDeleteAssistantPopup(true);
    };

    const handleDeleteAssistant = async () => {
        try {
            const response = await fetch(`${API_URL}/assistants/delete/${deletingAssistant.assistant_id}`, {
                method: 'DELETE',
            });

            if (!response.ok) throw new Error();

            const updatedResponse = await fetch(`${API_URL}/assistants/${user.sub}`);
            const updatedAssistants = await updatedResponse.json();
            setAssistants(updatedAssistants);

            toast.success('Assistant deleted');
        } catch (error) {
            console.error('Error deleting assistant:', error);
            toast.error('Failed to delete assistant');
        }
        setShowDeleteAssistantPopup(false);
        setDeletingAssistant(null);
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <button 
                className={`flex items-center h-9 space-x-1 px-4 py-2 bg-background dark:bg-background-dark ${
                    selectedAssistant 
                        ? 'text-text dark:text-text-dark' 
                        : 'text-gray-400 dark:text-gray-500'
                } text-sm rounded-2xl hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300`}
                onClick={() => setShowAssistantsPopup(true)}
            >
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="16" 
                    height="16" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke={selectedAssistant ? 'currentColor' : '#9CA3AF'}
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                    className="mr-2"
                >
                    <rect x="3" y="11" width="18" height="10" rx="2" />
                    <circle cx="12" cy="5" r="2" />
                    <path d="M12 7v4" />
                    <line x1="8" y1="16" x2="8" y2="16" />
                    <line x1="16" y1="16" x2="16" y2="16" />
                </svg>
                <span className="max-w-32 truncate">{selectedAssistant ? selectedAssistant.decrypted_title : 'No assistant'}</span>
            </button>

            {showAssistantsPopup && (
                <AssistantsPopup
                    onClose={() => setShowAssistantsPopup(false)}
                    assistants={assistants}
                    handleAssistantClick={handleAssistantClick}
                    handleEditClick={handleEditClick}
                    showDesignAssistantPopup={showDesignAssistantPopup}
                    setShowDesignAssistantPopup={setShowDesignAssistantPopup}
                    handleAssistantSubmit={handleAssistantSubmit}
                    editingAssistant={editingAssistant}
                    setEditingAssistant={setEditingAssistant}
                    handleDeleteClick={handleDeleteClick}
                    showDeleteAssistantPopup={showDeleteAssistantPopup}
                    setShowDeleteAssistantPopup={setShowDeleteAssistantPopup}
                    handleDeleteAssistant={handleDeleteAssistant}
                    deletingAssistant={deletingAssistant}
                    selectedAssistant={selectedAssistant}
                />
            )}
        </div>
    );
}

export default Assistants;