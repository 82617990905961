import React from 'react';
import EllyLogo from '../assets/logo-elly.png';

const Logo = ({ size = 'small' }) => {
    const sizeClasses = {
      small: 'w-6 h-6',
      medium: 'w-8 h-8',
      large: 'w-24 h-24'
    };
  
    return (
      <img 
        src={EllyLogo} 
        alt="Elly Logo" 
        className={`${sizeClasses[size]} text-center`} 
      />
    );
};

export default Logo;