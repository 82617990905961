import React from 'react';

const NewChatButton = ( {setMessages, firstMessageSent, setFirstMessageSent, isMinimized, setConversationId} ) => {

    const handleClick = () => {
        setMessages([]);
        setFirstMessageSent(false);
        setConversationId(null);
    };

    return (
        <button 
            className={`p-2 rounded-full  transition-colors text-sm ${
                isMinimized 
                    ? 'w-10' 
                    : 'w-44 text-left'
            } ${firstMessageSent ? 'hover:bg-background dark:hover:bg-hover-dark' : 'opacity-50 '}`}
            onClick={handleClick}
            aria-label="Start new chat"
            disabled={!firstMessageSent}
        >
            <div className={`flex items-center ${isMinimized ? 'justify-center' : 'justify-start'}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#7cb342"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4v16m8-8H4"
                    />
                </svg>
                {!isMinimized && <span className="ml-2 text-text dark:text-text-dark">Start new chat</span>}
            </div>
        </button>
    );
};

export default NewChatButton;