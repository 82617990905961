import React from 'react';
import AuthButton from './AuthButton';
import Logo from './Logo';

function LoginPage() {
    return (
        <div className="h-screen w-full flex items-center justify-center">
            <div className='flex flex-col items-center justify-center w-full sm:w-1/3 mx-auto h-2/4 bg-secondary dark:bg-secondary-dark rounded-3xl border border-border shadow-md p-5'>
                <div>
                    <Logo size="large" />
                </div>
                <br />
                <h1 className="text-4xl font-bold mb-8 dark:text-text-dark">Elly</h1>
                <h3 className="text-xl italic mb-8 text-center dark:text-text-dark"><em>Better AI conversations</em></h3>
                <AuthButton />
            </div>
        </div>
    );
}

export default LoginPage;