import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';

const ChatSearchPopup = ({ onClose, onSelect, user, conversations, setConversations }) => {
    const { isAuthenticated } = useAuth0();
    const [searchTerm, setSearchTerm] = useState('');
    const [conversationsForSearch, setConversationsForSearch] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const inputRef = useRef(null);

    const fetchConversations = useCallback(async () => {
        try {
            if (!isAuthenticated || !user) {
                console.log('User not authenticated or user data not available');
                return;
            }

            const response = await fetch(`${API_URL}/search/${user.sub}`);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();

            setConversationsForSearch(data);
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        inputRef.current?.focus();
        fetchConversations();
    }, [fetchConversations]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (filteredConversations.length > 0) {
            onSelect(filteredConversations[0].conversation_id);
        }
    };

    useEffect(() => {
        const filtered = conversationsForSearch
          .map(conv => ({
            ...conv,
            score: calculateRelevanceScore(conv, searchTerm)
          }))
          .sort((a, b) => b.score - a.score)
          .filter(conv => conv.score > 0)
          .filter(conv => !showStarredOnly || conv.starred);
    
        setFilteredConversations(filtered);
    }, [searchTerm, conversationsForSearch, showStarredOnly]);
    
    const calculateRelevanceScore = (conversation, term) => {
        if (!term) return 1;
        
        const searchTermLower = term.toLowerCase();
        const titleLower = conversation.title.toLowerCase();
        const memoryValue = conversation.decrypted_memory_value;
        
        let score = 0;
    
        if (titleLower.includes(searchTermLower)) {
          score += 5;
        }

        if (memoryValue) {
          const chatHistory = memoryValue.toLowerCase();
          if (chatHistory.includes(searchTermLower)) {
            score += 3;
          }
        }

        if (titleLower === searchTermLower) {
          score += 2;
        }
    
        return score;
    };

    const formatDate = (dateString) => {
        const now = new Date();
        const updatedDate = new Date(dateString);
        const diffTime = Math.abs(now - updatedDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 0) {
            return 'Today';
        } else {
            return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }
    };

    const handleStarClick = async (e, conversationId) => {
        e.stopPropagation();
        try {
            const response = await fetch(`${API_URL}/conversations/${conversationId}/star`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setFilteredConversations(prevConversations =>
                prevConversations.map(conv =>
                    conv.conversation_id === conversationId
                        ? { ...conv, starred: !conv.starred }
                        : conv
                )
            );

            setConversations(prevConversations =>
                prevConversations.map(conv =>
                    conv.conversation_id === conversationId
                        ? { ...conv, starred: !conv.starred }
                        : conv
                )
            );

        } catch (error) {
            console.error('Error starring conversation:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-xl" onClick={e => e.stopPropagation()}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg sm:text-xl font-bold text-text dark:text-text-dark">Type to start searching</h2>
                    <button
                        onClick={() => setShowStarredOnly(!showStarredOnly)}
                        className="px-3 py-1 rounded-md text-sm bg-background dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-border dark:border-border-dark"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill={showStarredOnly ? "gold" : "none"} viewBox="0 0 24 24" stroke={showStarredOnly ? "gold" : "#e0e0e0"} strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                        </svg>
                    </button>
                </div>
                
                <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search for conversations..."
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                    />
                    <div className="h-96 overflow-y-auto border border-border dark:border-border-dark rounded-md">
                        {filteredConversations.map((conversation) => (
                            <div 
                                key={conversation.conversation_id} 
                                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer rounded flex items-center w-full"
                            >
                                <div 
                                    className="w-full flex justify-between"
                                    onClick={() => {
                                        onSelect(conversation.conversation_id);
                                        onClose();
                                    }}
                                >
                                    <span className="text-text dark:text-text-dark text-sm w-full truncate">
                                        {conversation.title}
                                    </span>
                                    <span className="text-gray-400 dark:text-gray-400 text-xs w-1/5 flex justify-center mr-1">
                                        {formatDate(conversation.updated_at)}
                                    </span>
                                </div>
                                <div className="flex items-center space-x-2 w-1/7">
                                    <button
                                        onClick={(e) => handleStarClick(e, conversation.conversation_id)}
                                        className="focus:outline-none z-60 hover:bg-white dark:hover:bg-gray-500 rounded-lg p-1"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill={conversation.starred ? "gold" : "none"} viewBox="0 0 24 24" stroke={conversation.starred ? "gold" : "#e0e0e0"} strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChatSearchPopup;