import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import 'prosemirror-view/style/prosemirror.css';
import './index.css';

const getAuth0Config = () => {
  const origin = window.location.origin;
  
  // Base configuration
  const config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: origin,
    }
  };

  // Settings for review apps
  if (origin.includes('elly-pipelin-') || origin.includes('elly-staging-')) {
    config.authorizationParams = {
      ...config.authorizationParams,
      prompt: 'login' // Forces re-authentication for review apps
    };
  }

  return config;
};

const root = createRoot(document.getElementById('root'));
const auth0Config = getAuth0Config();

root.render(
  <React.StrictMode>
    <Auth0Provider {...auth0Config}>
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
