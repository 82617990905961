import React, { useState, useEffect, useRef } from 'react';

const EditTitlePopup = ({ conversation, onClose, onSubmit }) => {
    const [title, setTitle] = useState(conversation.title);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (title && title !== conversation.title) {
            onSubmit(title, conversation.conversation_id);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-md" onClick={e => e.stopPropagation()}>
                <h2 className="text-lg sm:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Edit conversation title</h2>
                <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
                    <input
                        ref={inputRef}
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                    />
                    <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="w-full sm:w-1/2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!title || title === conversation.title}
                            className="w-full sm:w-1/2 px-4 py-2 bg-primary text-white rounded hover:bg-primary-hover disabled:bg-gray-300 disabled:text-gray-500 transition-colors"
                        >
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditTitlePopup;