import React from 'react';
import DesignAssistantPopup from './DesignAssistantPopup';
import DeleteAssistantPopup from './DeleteAssistantPopup';

function AssistantsPopup({ 
    onClose, 
    assistants, 
    handleAssistantClick, 
    handleEditClick, 
    showDesignAssistantPopup,
    setShowDesignAssistantPopup,
    handleDeleteClick,
    handleAssistantSubmit,
    editingAssistant,
    setEditingAssistant,
    showDeleteAssistantPopup,
    setShowDeleteAssistantPopup,
    handleDeleteAssistant,
    deletingAssistant,
    selectedAssistant
}) {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div 
                className="bg-background dark:bg-background-dark border border-border dark:border-border-dark rounded-lg p-4 sm:p-6 w-full max-w-2xl"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg sm:text-xl font-bold text-text dark:text-text-dark">Your assistants</h2>
                    <button 
                        className="px-3 py-2 bg-background dark:bg-background-dark border border-border dark:border-border-dark text-text dark:text-text-dark text-sm rounded-lg hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300 flex items-center space-x-2"
                        onClick={() => setShowDesignAssistantPopup(true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="#7cb342">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                        <span>New Assistant</span>
                    </button>
                </div>

                <div className="max-h-[70vh] overflow-y-auto">
                    <div 
                        className={`group flex items-center justify-between p-3 mb-2 border border-dashed ${
                            !selectedAssistant ? 'border-primary dark:border-primary' : 'border-gray-300 dark:border-gray-600'
                        } rounded-lg hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300 cursor-pointer`}
                        onClick={() => handleAssistantClick(null)}
                    >
                        <span className={`${selectedAssistant ? 'text-gray-400 dark:text-gray-500' : 'text-primary dark:text-primary'}`}>No assistant (use default guidelines)</span>
                    </div>
                    {assistants.length === 0 ? (
                        <div className="text-center py-8 text-gray-400 dark:text-gray-500">
                            No assistants yet. Create your first one!
                        </div>
                    ) : (
                        assistants.map((assistant) => (
                            <div 
                                key={assistant.assistant_id}
                                className={`group flex items-center justify-between p-3 mb-2 border ${
                                    selectedAssistant === assistant ? 'border-primary dark:border-primary' : 'border-gray-200 dark:border-gray-700'
                                } rounded-lg hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300 cursor-pointer`}
                                onClick={() => handleAssistantClick(assistant)}
                            >
                                <span className={`${selectedAssistant === assistant ? 'text-primary dark:text-primary' : 'text-gray-900 dark:text-gray-100'}`}>{assistant.decrypted_title}</span>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={(e) => handleEditClick(e, assistant)}
                                        className="p-1.5 rounded-full hover:bg-background dark:hover:bg-background-dark transition-colors"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600 dark:text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                        </svg>
                                    </button>
                                    <button
                                        onClick={(e) => handleDeleteClick(e, assistant)}
                                        className="p-1.5 rounded-full hover:bg-background dark:hover:bg-background-dark transition-colors"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-600 dark:text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                {showDesignAssistantPopup && (
                    <DesignAssistantPopup
                        onClose={() => {
                            setShowDesignAssistantPopup(false);
                            setEditingAssistant(null);
                        }}
                        onSubmit={handleAssistantSubmit}
                        editAssistant={editingAssistant}
                    />
                )}
                {showDeleteAssistantPopup && (
                    <DeleteAssistantPopup
                        onClose={() => setShowDeleteAssistantPopup(false)}
                        onDelete={handleDeleteAssistant}
                        assistant={deletingAssistant}
                    />
                )}
            </div>
        </div>
    );
}

export default AssistantsPopup;