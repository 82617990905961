export const parseMessageContent = (content) => {
    if (typeof content === 'string') {
      try {
        return JSON.parse(content);
      } catch (error) {
        console.error('Error parsing message content:', error);
        return { text: content };
      }
    } else if (typeof content === 'object' && content !== null) {
      return content;
    } else {
      console.error('Unexpected content type:', typeof content);
      return { text: String(content) };
    }
};
  
export const formatMessages = (messages) => {
    return messages.map(message => ({
      role: message.role,
      content: message.role === 'user' ? parseMessageContent(message.decrypted_content) : message.decrypted_content,
      model: message.model
    }));
};